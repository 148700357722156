import React, { useEffect, useRef, useState } from "react"
import "react-dates/initialize"
import { DayPickerRangeController } from "react-dates"
import ArrowIcon from "../planning/arrowIcon"
import RemoveIcon from "../../images/remove-search.svg"
import { Overlay } from "react-bootstrap"

const DateFilter = ({ placeholder, setQuery }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState({
        startDate: undefined,
        endDate: undefined
    })
    const [focusedInput, setFocusedInput] = useState("startDate")
    const ref = useRef(null)

    useEffect(() => {
        setQuery({
            from: data?.startDate ? data.startDate.format("YYYY-MM-DD") : undefined,
            to: data?.endDate ? data.endDate.format("YYYY-MM-DD") : undefined,
            page: 1
        })
    }, [data.startDate, data.endDate])

    const config = {
        hideKeyboardShortcutsPanel: true,
        daySize: 35,
        startDate: data.startDate,
        endDate: data.endDate,
        focusedInput,
        onFocusChange: focusedInput => setFocusedInput(focusedInput || "startDate"),
        onDatesChange: ({ startDate, endDate }) => setData({ startDate, endDate }),
        navPrev: (
            <div className="navigation-button-filter button-prev">
                <ArrowIcon color="#3d4998" className="arrow-prev" />
            </div>
        ),
        navNext: (
            <div className="navigation-button-filter button-next">
                <ArrowIcon color="#3d4998" className="arrow-next" />
            </div>
        )
    }

    return (
        <div ref={ref} className="dropdown dropdown-bookings dropdown-date show me-3 mt-2">
            <div className="selected-dates-wrapper">
                {data?.startDate ? (
                    <div className="selected-date">
                        {data.startDate.format("MM/DD/YY")} {data?.endDate ? `- ${data.endDate.format("MM/DD/YY")}` : ""}
                        <button onClick={() => setData({ startDate: undefined, endDate: undefined })}>
                            <img src={RemoveIcon} alt="Remove Icon" />
                        </button>
                    </div>
                ) : (
                    <div onClick={() => setIsOpen(!isOpen)}>{placeholder}</div>
                )}
            </div>

            <div className="select-arrow-wrapper" onClick={() => setIsOpen(!isOpen)}>
                <svg
                    className={`arrow-select ${isOpen ? "open" : ""}`}
                    width="9"
                    height="5"
                    viewBox="0 0 9 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.895925 4.7813L0.117421 4.06899L4.51574 0.0446429L8.91406 4.06899L8.13556 4.7813L4.51574 1.46926L0.895925 4.7813Z"
                        fill="#616371"
                    />
                </svg>
            </div>

            {typeof window !== "undefined" && (
                <Overlay show={isOpen} trigger="click" rootClose target={ref} placement="bottom" onHide={() => setIsOpen(false)}>
                    <div className="dropdown-menu date show">
                        <DayPickerRangeController {...config} />
                    </div>
                </Overlay>
            )}
        </div>
    )
}

export default DateFilter
