import React, { useCallback, useEffect, useState } from "react"
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { CARDS_PER_PAGE } from "../../enums/screenWidth"
import { formatBookingQueries } from "../../utils/bookingQueries"
import fetchQuery from "../../utils/fetchQuery"
import BookingCard from "./bookingCard"
import DateFilter from "./dateFilter"
import FilterSelect from "./filterSelect"
import PaginationComponent from "./pagination"
import SearchInput from "./searchInput"
import SortBy from "./sortBy"

const BookingContent = () => {
    const [refetch, setRefetch] = useState(false)
    const [cards, setCards] = useState([])
    const [yachts, setYachts] = useState([])
    const [routes, setRoutes] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [errorMsg, setErrorMsg] = useState(null)
    const [query, setQuery] = useQueryParams({
        s: StringParam,
        y: withDefault(ArrayParam, []),
        from: StringParam,
        to: StringParam,
        r: withDefault(ArrayParam, []),
        g: StringParam,
        cname: StringParam,
        sortBy: withDefault(NumberParam, 1000),
        sortOrder: withDefault(NumberParam, 1000),
        page: withDefault(NumberParam, 1)
    })

    const {
        g: guestsValue,
        s: searchValue,
        cname: customerValue,
        sortOrder,
        y: yachtsSelected,
        r: routesSelected,
        sortBy,
        page
    } = query

    const getBookings = async () => {
        setErrorMsg(null)
        const params = formatBookingQueries({ ...query, totalCount, page: page })

        await fetchQuery({
            method: "POST",
            endPoint: "configuration/booking/getbookinglist",
            body: params,
            successCallback: bookings => {
                setCards(bookings.items)

                setTotalCount(bookings.totalCount)
            },
            failCallback: () => {
                setErrorMsg("Something went wrong. Please reload the page.")
            }
        })
    }

    const getYachts = async () => {
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/vessel/GetVesselList",
            successCallback: yachts => {
                setYachts(yachts.items)
            }
        })
    }

    const getRoutes = async () => {
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/route/GetRouteList",
            successCallback: routes => {
                setRoutes(routes.items)
            }
        })
    }

    const deleteCard = useCallback(
        async (id, setModal, setLoading) => {
            await fetchQuery({
                method: "POST",
                endPoint: "configuration/booking/DeleteBooking",
                body: id,
                toJSON: false,
                successCallback: response => {
                    if (response.ok) {
                        setLoading(false)
                        setModal(false)
                        setCards(c => c.filter(c => c.id !== id))
                        setTotalCount(c => c - 1)

                        if (cards.length - 1 === 0 && page - 1 !== 0) {
                            setQuery({ page: page - 1 })
                        } else {
                            setRefetch(true)
                        }
                    }
                },
                failCallback: () => {
                    setLoading(false)
                }
            })
        },
        [cards, page]
    )

    useEffect(() => {
        getBookings()
    }, [query])

    useEffect(() => {
        if (refetch) {
            getBookings()
            setRefetch(false)
        }
    }, [refetch])

    useEffect(() => {
        getRoutes()
        getYachts()
    }, [])

    const formatYachtsFilterItems = useCallback(
        items => {
            return items.map(i => ({ id: i.id, name: i.vesselName }))
        },
        [yachts]
    )

    const formatRoutesFilterItems = useCallback(
        items => {
            return items.map(i => ({ id: i.id, name: i.routeName }))
        },
        [routes]
    )

    return (
        <div className="container-fluid container-xxl">
            <div className="row">
                <div className="col-12 bookings">
                    <div className="bookings__title d-flex align-items-center justify-content-between">
                        <h1 className="my-0">Bookings</h1>
                        <div className="d-md-none d-block">
                            <SortBy mobile sortBy={sortBy} sortOrder={sortOrder} setQuery={setQuery} />
                        </div>
                    </div>

                    <div className="row bookings__filters align-items-start py-2 pb-3">
                        <div className="col-12 col-md-10">
                            <div className="d-flex flex-wrap">
                                <SearchInput
                                    name="s"
                                    value={searchValue}
                                    type="text"
                                    placeholder="Search..."
                                    maxWidth={128}
                                    setQuery={setQuery}
                                />
                                <FilterSelect
                                    setQuery={setQuery}
                                    name="y"
                                    placeholder="Yacht"
                                    selectedOptions={yachtsSelected}
                                    options={formatYachtsFilterItems(yachts)}
                                />
                                <DateFilter placeholder="Trip date" setQuery={setQuery} />
                                <FilterSelect
                                    setQuery={setQuery}
                                    name="r"
                                    placeholder="Route"
                                    selectedOptions={routesSelected}
                                    options={formatRoutesFilterItems(routes)}
                                />
                                <SearchInput
                                    name="g"
                                    value={guestsValue}
                                    type="number"
                                    placeholder="Number of guests..."
                                    maxWidth={197}
                                    setQuery={setQuery}
                                />
                                <SearchInput
                                    name="cname"
                                    value={customerValue}
                                    type="text"
                                    placeholder="Customer name..."
                                    maxWidth={192}
                                    setQuery={setQuery}
                                />
                            </div>
                        </div>

                        <div className="bookings__filters-right col-md-2 d-none d-md-block">
                            <SortBy sortBy={sortBy} sortOrder={sortOrder} setQuery={setQuery} />
                        </div>
                    </div>

                    <div className="bookings__cards row">
                        <div className="col-12">
                            {!errorMsg && cards.length ? (
                                cards.map(c => <BookingCard key={c.id} data={c} deleteCard={deleteCard} />)
                            ) : (
                                <div style={{ minHeight: "500px" }} className="h-100 row align-items-center justify-content-center">
                                    {errorMsg ? errorMsg : "No bookings available."}
                                </div>
                            )}
                        </div>

                        {typeof window !== "undefined" && (
                            <div className="custom__pagination">
                                <PaginationComponent
                                    itemsCount={totalCount}
                                    itemsPerPage={CARDS_PER_PAGE}
                                    currentPage={page}
                                    setQuery={setQuery}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingContent
