import React, { useCallback, useRef, useState } from "react"
import SortIcon from "../../images/sort.svg"
import PropTypes from "prop-types"
import { Overlay } from "react-bootstrap"

const SortBy = ({ sortOrder, sortBy, mobile, setQuery }) => {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef(null)

    const Item = ({ title, name, code }) => {
        const active = +sortBy === +code ? "active" : ""

        const setActive = useCallback(() => {
            setQuery({ sortBy: +code === +sortBy ? undefined : code })
        }, [sortBy])

        return (
            <div className="dropdown-item radio" onClick={setActive}>
                <div className={`radio-select ${active}`}></div>
                <label className={`ps-2 ${active}`} htmlFor={name}>
                    {title}
                </label>
            </div>
        )
    }

    const ArrowIcon = ({ color, className }) => (
        <svg width="10" height="15" viewBox="0 0 10 15" className={className} fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M5 13.4224L1 9.42242M5 13.4224L9 9.42242M5 13.4224L5 0.922424" stroke={color || "#616371"} />
        </svg>
    )

    const OrderItem = ({ code, title }) => {
        const onSelect = () => {
            setQuery({ sortOrder: code })
        }

        return (
            <div className="dropdown-item radio order" onClick={onSelect}>
                <ArrowIcon className={+code === 1000 ? "asc" : "dsc"} color={`${+sortOrder === +code ? "#3d4998" : ""}`} />
                <label className={`ps-2 ${+sortOrder === +code ? "active" : ""}`}>{title}</label>
            </div>
        )
    }

    let selectedName = undefined
    switch (sortBy) {
        case 1000:
            selectedName = "trip date"
            break
        case 2000:
            selectedName = "number of guests"
            break
        case 3000:
            selectedName = "total amount"
            break
    }

    return (
        <div ref={ref} className={`dropdown dropdown-sort ${mobile ? "dropdown-mobile" : ""} show mt-2`}>
            {!mobile ? (
                <>
                    <div className="selected-sort position-relative">
                        Sort by <b>{selectedName}</b>
                    </div>

                    <div className="select-arrow-wrapper" onClick={() => setIsOpen(!isOpen)}>
                        <svg
                            className={`arrow-select ${isOpen ? "open" : ""}`}
                            width="9"
                            height="5"
                            viewBox="0 0 9 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.895925 4.7813L0.117421 4.06899L4.51574 0.0446429L8.91406 4.06899L8.13556 4.7813L4.51574 1.46926L0.895925 4.7813Z"
                                fill="#616371"
                            />
                        </svg>
                    </div>
                </>
            ) : (
                <button onClick={() => setIsOpen(!isOpen)} className="sort-mobile">
                    <img src={SortIcon} alt="Sort Icon" />
                </button>
            )}

            {typeof window !== "undefined" && (
                <Overlay
                    show={isOpen}
                    trigger="click"
                    rootClose
                    target={ref}
                    container={ref}
                    placement="bottom"
                    onHide={() => setIsOpen(false)}
                >
                    <div className="dropdown-menu sort show">
                        <Item code={1000} title="Trip date" name="tripDate" />
                        <Item code={2000} title="Number of guests" name="numberOfGuests" />
                        <Item code={3000} title="Total amount" name="totalAmount" />
                        <div className="dropdown-divider"></div>
                        <OrderItem code="1000" title="Ascending" />
                        <OrderItem code="2000" title="Descending" />
                    </div>
                </Overlay>
            )}
        </div>
    )
}

SortBy.propTypes = {
    sortOrder: PropTypes.number,
    sortBy: PropTypes.number,
    mobile: PropTypes.bool
}

export default SortBy
