import React, { useState } from "react"
import moment from "moment"
import AnchorIcon from "../../images/anchor.svg"
import PeopleIcon from "../../images/people.svg"
import CoinIcon from "../../images/coin.svg"
import ArrowIcon from "../planning/arrowIcon"
import { Link } from "gatsby"
import formatPrice from "../../utils/priceFormatter"
import PropTypes from "prop-types"

const BookingCard = ({ data, deleteCard }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const created = moment(data.createdAt).format("DD/MM/YY")
    const to = data?.from === data?.to ? undefined : data?.to
    const bookedDateFormated = `${moment(data.from).format("Do MMMM")} ${to ? ` -  ${moment(to).format("Do MMMM")}` : ""}`

    const onDelete = () => {
        setShowModal(true)
    }

    const ChapterItem = ({ title, content, price, count, children, className }) => {
        let blockContent = content

        switch (title) {
            case "Dietary requirements":
                blockContent = content ? content : "No requirements"
                break
        }

        return (
            <div className={`${className ?? ""} booking__card__body_chapter-item mb-2`}>
                <p className="booking__card__body_sub-title mb-1">{title}</p>
                <div className="mr-2 d-flex align-items-center">
                    {!children ? (
                        <>
                            <p className="booking__card__body_text my-0">{blockContent}</p>
                            {price ? (
                                <div className="booking__card__body_price ms-2">
                                    €{price} {count > 1 ? `x ${count}` : ""}
                                </div>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        children
                    )}
                </div>
            </div>
        )
    }

    const DeleteModal = () => {
        return (
            <div className="modal d-block modal-edit">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content px-3">
                        <div className="modal-body">
                            <p className="mb-0">Delete this booking?</p>
                        </div>
                        <div className="modal-footer pb-2">
                            <button type="button" className="btn btn-cancel" onClick={() => setShowModal(false)}>
                                cancel
                            </button>
                            <button
                                disabled={isLoading}
                                onClick={() => deleteCard(data.id, setShowModal, setIsLoading)}
                                className="button fill dark"
                            >
                                <span>delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="booking__card mb-3">
            <div className="container px-3">
                <div className={`py-3 col-12 position-relative padding-bottom-header-card ${isOpen ? "open" : ""}`}>
                    <div className="col-12 pt-1 booking__card_created">{created}</div>
                    <div className="row align-items-center booking__card_info">
                        <div className="col-12 col-md-2">
                            <p className="fs-4 booking__card_info_name mb-2 my-md-0">{data.fullName}</p>
                        </div>
                        <div className="col-12 col-md-3">
                            <p className="booking__card_info_booked-date mb-3 my-md-0">{bookedDateFormated}</p>
                        </div>
                        <div className="col-12 col-md-2 booking__card_info_yacht-wrapper mb-2 mb-md-0 d-flex align-items-center">
                            <img src={AnchorIcon} alt="Anchor image" />
                            <p className="booking__card_info_yacht-name my-0">{data.vesselName}</p>
                        </div>
                        <div className="col-12 col-md-5 align-items-center">
                            <div className="row">
                                <div className="col-2 booking__card_info_guests d-flex align-items-center">
                                    <img src={PeopleIcon} alt="People icon" />
                                    <p className="booking__card_info_yacht-guests my-0">{data.guests}</p>
                                </div>
                                <div className="col-10 d-flex booking__card_info_price align-items-center">
                                    <img src={CoinIcon} alt="Coin icon" />
                                    <p className="booking__card_info_yacht-price my-0">€{formatPrice(data.totalPrice)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        data-bs-toggle="collapse"
                        data-bs-target={`#expense_${data.id}`}
                        aria-expanded={isOpen}
                        aria-controls={`expense_${data.id}`}
                        onClick={() => setIsOpen(!isOpen)}
                        className={`booking__card__arrow-open ${isOpen ? "open" : ""} position-absolute`}
                    >
                        <ArrowIcon color="#3D4998" />
                    </div>
                </div>
                <div className="collapse" id={`expense_${data.id}`}>
                    <div className="booking__card__body col-12">
                        <div className="booking__card__body_booking py-3 col-12 d-flex flex-wrap">
                            <div className="col-md-2 col-12">
                                <p className="booking__card__body_chapter">Booking</p>
                            </div>
                            <div className="col-md-3 col-12">
                                <ChapterItem title="Yacht" content={data.vesselName} price={formatPrice(data.rentalPrice)} />
                                <ChapterItem title="Extras">
                                    <div className="d-flex flex-column">
                                        {data.extrasCopy?.map(e => {
                                            return (
                                                <div key={e.id} className="d-flex justify-content-between">
                                                    <p className="booking__card__body_text my-0">{e.extraName}</p>
                                                    {e.price ? (
                                                        <div className="booking__card__body_price ms-2">
                                                            €{formatPrice(e.price)} {e.count > 1 ? `x ${e.count}` : ""}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </ChapterItem>
                            </div>
                            <div className="col-md-3 col-12">
                                <ChapterItem title="Trip date" content={bookedDateFormated} />
                                <ChapterItem title="Dietary requirements" content={data.dietaryRequirements} />
                            </div>
                            <div className="col-md-4 col-12">
                                <ChapterItem title="Route" content={data.routeCopy?.routeName} />
                            </div>
                        </div>
                        <div className="booking__card__body_customer py-3 col-12 d-flex flex-wrap">
                            <div className="col-md-2 col-12">
                                <p className="booking__card__body_chapter">Customer details</p>
                            </div>
                            <div className="col-md-3 col-12 d-flex flex-row flex-md-column flex-wrap">
                                <ChapterItem title="First name" className="col-md-12 col-6" content={data.firstName} />
                                <ChapterItem title="Surname" className="col-md-12 col-6" content={data.lastName} />
                                <ChapterItem
                                    title="Date of Birth"
                                    className="col-md-12 col-6"
                                    content={data.birthDate ? moment(data.birthDate).format("DD/MM/YYYY") : null}
                                />
                            </div>
                            <div className="col-md-3 col-12">
                                <ChapterItem title="Email" content={data.emailAddress} />
                                <ChapterItem title="Phone number" content={data.phoneNumber} />
                            </div>
                            <div className="col-md-4 col-12 d-flex flex-wrap">
                                <ChapterItem title="Number of guests" className="col-12" content={data.guests} />
                                <ChapterItem title="Adults (16+)" className="col-md-12 col-6" content={data.adults} />
                                <ChapterItem title="Children (0-15)" className="col-md-12 col-6" content={data.children} />
                            </div>
                        </div>
                        <div className="booking__card__body_payment py-3 col-12 d-flex flex-wrap">
                            <div className="col-md-2 col-12">
                                <p className="booking__card__body_chapter">Payment details</p>
                            </div>
                            <div className="col-md-3 col-12 d-flex flex-wrap">
                                <ChapterItem title="First name" className="col-md-12 col-6" content={data.billingFirstName} />
                                <ChapterItem title="Surname" className="col-md-12 col-6" content={data.billingLastName} />
                                <ChapterItem title="Address">
                                    <div className="booking__card__body_adress">
                                        <div>{data.address}</div>
                                        <div>{data.city}</div>
                                        <div>{data.country}</div>
                                    </div>
                                </ChapterItem>
                            </div>
                            <div className="col-md-3 col-12 d-flex flex-row flex-md-column flex-wrap">
                                <ChapterItem title="Card number" className="col-6" content={`**** ${data.cardNumber}`} />
                                <ChapterItem
                                    title="Expiry date"
                                    className="col-6"
                                    content={moment(data.cardExpiry).format("MM/YYYY")}
                                />
                            </div>
                            <div className="col-md-4 col-12 d-flex flex-row flex-md-column flex-wrap">
                                <ChapterItem
                                    title="Total amount"
                                    className="col-md-12 col-6"
                                    content={`€${formatPrice(data.totalPrice)}`}
                                />
                                <ChapterItem title="Confirmation" className="col-md-12 col-6">
                                    <Link state={{ data }} to="/confirmation">
                                        confirmation
                                    </Link>
                                </ChapterItem>
                            </div>
                        </div>
                        <button type="button" onClick={onDelete} className="btn button-delete p-0 mb-3">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            {showModal && <DeleteModal />}
        </div>
    )
}

BookingCard.propTypes = {
    data: PropTypes.object.isRequired,
    deleteCard: PropTypes.func.isRequired
}

export default BookingCard
