import React, { useEffect, useState } from "react"
import RemoveIcon from "../../images/remove-search.svg"
import PropTypes from "prop-types"

const SearchInput = ({ name, type, placeholder, maxWidth, value, setQuery }) => {
    const [searchValue, setSearchValue] = useState(value ?? "")
    const [onFocus, setOnFocus] = useState(false)

    const search = searchValue => {
        setQuery({ [`${name}`]: searchValue || undefined, page: 1 })
    }

    useEffect(() => {
        let timeout = undefined

        if (onFocus) {
            timeout = setTimeout(() => search(searchValue), 1000)
        }

        return () => clearTimeout(timeout)
    }, [searchValue])

    useEffect(() => {
        if (!value) setSearchValue("")
    }, [value])

    const onSearch = e => {
        setSearchValue(e.target.value)
    }

    const onRemomeValue = () => {
        setSearchValue("")
        setQuery({ [`${name}`]: undefined, page: 1 })
    }

    return (
        <div style={{ maxWidth, width: "100%" }} className="me-3 mt-2 position-relative">
            <input
                onFocusCapture={() => setOnFocus(true)}
                onBlur={() => setOnFocus(false)}
                type={type}
                className="search"
                name={name}
                placeholder={placeholder}
                value={searchValue}
                onChange={onSearch}
            />
            {searchValue && (
                <button onClick={onRemomeValue} className="remove-button-search">
                    <img src={RemoveIcon} alt="Remove Icon" />
                </button>
            )}
        </div>
    )
}

SearchInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    maxWidth: PropTypes.number.isRequired,
    value: PropTypes.string
}

export default SearchInput
