import React from "react"
import BookingContent from "../components/bookings/bookingContent"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { withAuth } from "../hoc/withAuth"

const Booking = withAuth(({ authorized }) => {
    return (
        <Layout>
            <Seo title="Bookings" />
            <NavBar whiteNav bookNow={false} />

            <div className="top-gradient-container">
                <BookingContent token={authorized} />
            </div>

            <Footer />
        </Layout>
    )
})

export default Booking
